// src/components/VerifyOtpPasswordResetForm.js
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { verifyOtpPasswordReset } from './../../redux/actions/authActions';

const VerifyOtpPasswordResetForm = () => {
  const [email, setEmail] = useState('');
  const [otp, setOtp] = useState('');
  const dispatch = useDispatch();

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(verifyOtpPasswordReset({ email, otp }));
  };

  return (
    <form onSubmit={handleSubmit}>
      <h2>Verify OTP for Password Reset</h2>
      <input type="email" placeholder="Email" onChange={(e) => setEmail(e.target.value)} />
      <input type="text" placeholder="OTP" onChange={(e) => setOtp(e.target.value)} />
      <button type="submit">Verify OTP</button>
    </form>
  );
};

export default VerifyOtpPasswordResetForm;
