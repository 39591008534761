// src/App.js
import React from 'react';
import { Route, Routes } from 'react-router-dom';

// Pages
import Landing from '../componenst/landing/Landing';
import About from '../pages/about/About';
import SearchResults from '../layouts/SearchResults';


// Product

import ProductList from '../componenst/product/ProductList';
import ProductDetail from '../componenst/product/ProductDetail';
import CartPage from '../componenst/cart/ViewCart';
import ProductListByAge from '../componenst/filter/AgeBased';
import CheckoutForm from '../componenst/checkout/ChekoutForm';
// import ConfirmationPage from '../componenst/checkout/Confirmation';


// Authentication
import SigninForm from '../componenst/auth/SigninForm';
import SignupForm from '../componenst/auth/SignupForm';
import VerifyOtpForm from '../componenst/auth/VerifyOtpForm';
import ForgotPasswordForm from '../componenst/auth/ForgotPasswordForm';
import VerifyOtpPasswordResetForm from '../componenst/auth/VerifyOtpPasswordResetForm';
import ResetPasswordForm from '../componenst/auth/ResetPasswordForm';

// User
import Profile from '../componenst/user/Profile';

// Parent 

import ParentReview from '../componenst/parent/ParentReview';
import ResultsPage from '../componenst/parent/ProductParent';

// Footer

import PrivacyPolicy from './../componenst/footer/Privacy'
import TermsConditions from './../componenst/footer/Term'
import ContactForm from '../pages/contact/Contact';

const App = () => (

    <Routes>
      {/* Pages */}
      <Route path="/" element={<Landing />} />
      <Route path='/about' element={<About/>} />
      <Route path='/contact' element={<ContactForm/>} />
      <Route path="/search" element={<SearchResults />} />

      {/* Product */}
      <Route path="/product" element={<ProductList />} />
      <Route path="/products/viewproduct/:productId" element={<ProductDetail />} />
      <Route path="/viewcart" element={<CartPage />} />
      <Route path="/product/by-age/:ageGroup" element={<ProductListByAge />} />
      <Route path='/checkout' element={<CheckoutForm/>} />
      <Route path="/product-new" element={<ProductList />} />
      {/* <Route path="/checkout/:checkoutId" element={<ConfirmationPage />} /> */}

      {/* Authentication */}
      <Route path="/signup" element={<SignupForm />} />
      <Route path="/verify-otp" element={<VerifyOtpForm />} />
      <Route path="/signin" element={<SigninForm />} />
      <Route path="/forgot-password" element={<ForgotPasswordForm />} />
      <Route path="/verify-otp-password-reset" element={<VerifyOtpPasswordResetForm />} />
      <Route path="/reset-password" element={<ResetPasswordForm />} />

      {/* User */}
      <Route path="/profile" element={<Profile />} />

      {/* Parent */}

      <Route path="/parent-hood" element={<ParentReview />} />
      <Route path="/suggest-products-for-you" element={<ResultsPage />} />

      {/* footer */}
      <Route path="/privacy-policy" element={<PrivacyPolicy/>} />
      <Route path="/term-and-condition" element={<TermsConditions/>} />

      



    </Routes>
  
);

export default App;
