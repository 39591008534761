const initialState = {
  order: null,
  checkout: null,
  loading: false,
  error: null,
};

export const orderReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'CREATE_ORDER_SUCCESS':
      return { ...state, order: action.payload, loading: false };
    case 'CREATE_ORDER_FAIL':
      return { ...state, error: action.payload, loading: false };
    case 'VERIFY_PAYMENT_SUCCESS':
      return { ...state, order: { ...state.order, paymentStatus: 'Paid' }, loading: false };
    case 'VERIFY_PAYMENT_FAIL':
      return { ...state, error: action.payload, loading: false };
    case 'FETCH_CHECKOUT_SUCCESS':
      return { ...state, checkout: action.payload, loading: false };
    case 'FETCH_CHECKOUT_FAIL':
      return { ...state, error: action.payload, loading: false };
    default:
      return state;
  }
};
