// src/pages/SearchResults.js
import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { addToCart } from './../redux/actions/cartActions';

const SearchResults = () => {
  const location = useLocation();
  const query = new URLSearchParams(location.search).get('name');
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);

  const [quantity, setQuantity] = useState(1);
  const [isAdded, setIsAdded] = useState(null);  // Track if item is added to the cart
  const dispatch = useDispatch();



  const handleAddToCart = (productId, quantity) => {
    dispatch(addToCart(productId, quantity));
    setIsAdded(productId);  // Set the product ID to indicate it's added
    setTimeout(() => setIsAdded(null), 2000);  // Reset after 2 seconds
  };

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await axios.get(`http://localhost:5000/api/products/search?name=${query}`);
        setProducts(response.data);
      } catch (error) {
        console.error("Error fetching search results:", error);
      } finally {
        setLoading(false);
      }
    };
    if (query) fetchProducts();
  }, [query]);

  if (loading) return <p>Loading...</p>;

  return (
  
  

    <div>

    {products.length ? (
      <section
      id="Projects"
      className="w-fit mx-auto grid grid-cols-1 lg:grid-cols-3 md:grid-cols-2 justify-items-center justify-center gap-y-20 gap-x-14 mt-10 mb-5"
    >
      {products.map((product, index) => (
        <div
          key={index}
          className="w-72 bg-white shadow-md rounded-xl duration-500 hover:scale-105 hover:shadow-xl"
        >
          <Link to={`/products/viewproduct/${product?._id}`}>
            <img
              src={product.images[0]} // Assumes 'images' is an array
              alt={product.name}
              className="h-80 w-72 object-cover rounded-t-xl"
            />
            <div className="px-4 py-3 w-72">
              <span className="text-gray-400 mr-3 uppercase text-xs">
                {product.brand || 'Brand'}
              </span>
              <p className="text-lg font-bold text-black truncate block capitalize">
                {product.name}
              </p>
              <div className="flex items-center">
                <p className="text-lg font-semibold text-black cursor-auto my-3">
                  &#8377; {product.price}
                </p>
                {product.originalPrice && (
                  <del>
                    <p className="text-sm text-gray-600 cursor-auto ml-2">
                      &#8377; {product.originalPrice}
                    </p>
                  </del>
                )}
                <div className="ml-auto">
                  {/* Add to Cart Icon */}
                  <i
                    className={`bi text-2xl cursor-pointer transition-transform duration-300 ${
                      isAdded === product._id
                        ? 'bi-cart-check text-green-500 animate-pulse'
                        : 'bi-cart-plus'
                    }`}
                    onClick={() => handleAddToCart(product._id, quantity)}
                  ></i>
                </div>
              </div>
            </div>
          </Link>
        </div>
      ))}
    </section>
    ) : (
      <p>No products found.</p>
    )}
  </div>
  );
};

export default SearchResults;
