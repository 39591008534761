import api, { setAuthToken } from './../../service/api';

// Action Types
export const SIGNUP = 'SIGNUP';
export const VERIFY_OTP = 'VERIFY_OTP';
export const SIGNIN = 'SIGNIN';
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const VERIFY_OTP_PASSWORD_RESET = 'VERIFY_OTP_PASSWORD_RESET';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const GET_PROFILE = 'GET_PROFILE';
export const LOGOUT = 'LOGOUT';

// Action Creators
export const signup = (userData) => async (dispatch) => {
  try {
    const response = await api.post('/auth/signup', userData);
    if (response.status === 201) {
      dispatch({ type: 'SIGNUP_SUCCESS', payload: response.data });
      return response; // Return the response so it can be used in the component
    }
  } catch (error) {
    dispatch({ type: 'SIGNUP_FAILURE', payload: error.message });
    throw error;
  }
};

export const verifyOtp = (otpData) => async (dispatch) => {
  try {
    const response = await api.post('/auth/verify-otp', otpData);
    dispatch({ type: VERIFY_OTP, payload: response.data });
  } catch (error) {
    console.error('OTP verification failed', error);
  }
};

export const signin = (loginData) => async (dispatch) => {
    try {
      const response = await api.post('/auth/signin', loginData);
      const token = response.data.token;
      if (token) {
        localStorage.setItem('token', token);  // Store token in localStorage
        setAuthToken(token);  // Set token to headers
        dispatch({ type: SIGNIN, payload: response.data });
      } else {
        console.error('Token not received');
      }
      window.location.href = "/";
    } catch (error) {
      console.error('Signin failed', error);
    }
  };
  

export const forgotPassword = (emailData) => async (dispatch) => {
  try {
    const response = await api.post('/auth/forgot-password', emailData);
    dispatch({ type: FORGOT_PASSWORD, payload: response.data });
  } catch (error) {
    console.error('Forgot password failed', error);
  }
};

export const verifyOtpPasswordReset = (otpData) => async (dispatch) => {
  try {
    const response = await api.post('/auth/verify-otp-password-reset', otpData);
    dispatch({ type: VERIFY_OTP_PASSWORD_RESET, payload: response.data });
  } catch (error) {
    console.error('OTP verification for password reset failed', error);
  }
};

export const resetPassword = (resetData) => async (dispatch) => {
  try {
    const response = await api.post('/auth/reset-password', resetData);
    dispatch({ type: RESET_PASSWORD, payload: response.data });
  } catch (error) {
    console.error('Reset password failed', error);
  }
};

export const getProfile = () => async (dispatch) => {
    const token = localStorage.getItem('token');  
    
    if (token) {
      setAuthToken(token);  
    } else {
      console.error('No token found');
      return;  // Stop if token is missing
    }
  
    try {
      const response = await api.get('/user/profile/me');
      dispatch({ type: GET_PROFILE, payload: response.data });
    } catch (error) {
      console.error('Get profile failed', error.response ? error.response.data : error);
    }
  };

  