import React from 'react';
import { useNavigate } from 'react-router-dom';

const ImageGallery = () => {
  const navigate = useNavigate()
  const images = [
    {
      url: 'https://cdn.pixabay.com/photo/2022/03/05/09/36/child-7048770_640.jpg',
      caption: '0-1 year',
      ageGroup: '0-1'
    },
    {
      url: 'https://cdn.pixabay.com/photo/2020/09/18/21/12/laughing-5582975_1280.jpg',
      caption: '1-2 years',
      ageGroup: '1-2'
    },
    {
      url: 'https://cdn.pixabay.com/photo/2017/12/24/13/35/baby-3036925_640.jpg',
      caption: '2-5 years',
       ageGroup: '2-5'
    },
    {
      url: 'https://cdn.pixabay.com/photo/2021/02/06/09/08/baby-5987512_640.jpg',
      caption: '6+ years',
       ageGroup: '5+'
    },
  ];

  return (
    <div className="container mx-auto px-4 my-16">
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8">
        {images.map((image, index) => (
          <div key={index} className="text-center"  onClick={()=>navigate(`/product/by-age/${image?.ageGroup}`)} style={{cursor : "pointer"}}>
            <div className="w-40 h-40 mx-auto mb-4 overflow-hidden rounded-full">
              <img
                src={image.url}
                alt={image.caption}
                className="object-cover w-full h-full"
              />
            </div>
            <p className="text-lg font-semibold">{image.caption}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ImageGallery;
