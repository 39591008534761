import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import api from "../../service/api";

const ParentReview = () => {
  const [formData, setFormData] = useState({
    ageGroup: "",
    skillStage: "",
    interests: "",
    areaSize: "",
    budget: "",
  });

  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await api.post("/products/filter", formData);
      navigate("/suggest-products-for-you", { state: { products: response.data } });
    } catch (error) {
      console.error("Error submitting the form:", error);
    }
  };

  return (
    <div className="flex lg:h-screen bg-indigo-700">
      <div className="w-full max-w-xl xs:max-w-xs m-auto bg-indigo-100 rounded-lg p-6 shadow-lg">
        <header className="text-center mb-5">
          <img
            className="w-20 mx-auto mb-5"
            src="https://img.icons8.com/fluent/344/year-of-tiger.png"
            alt="Logo"
          />
          <h2 className="text-indigo-700 text-2xl font-bold">Parent Review</h2>
        </header>

        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label className="block mb-2 text-indigo-500" htmlFor="ageGroup">
              What age is your child, and what stage of development are they currently in?
            </label>
            <select
              name="ageGroup"
              value={formData.ageGroup}
              onChange={handleChange}
              className="w-full p-2 text-indigo-700 border-b-2 border-indigo-500 outline-none focus:bg-gray-300"
            >
              <option>Select</option>
              <option>0-1</option>
              <option>1-2</option>
              <option>2-5</option>
              <option>5+</option>
            </select>
          </div>

          <div className="mb-4">
            <label className="block mb-2 text-indigo-500" htmlFor="skillStage">
              What specific skills or areas of development are you hoping to support or enhance with the toy?
            </label>
            <select
              name="skillStage"
              value={formData.skillStage}
              onChange={handleChange}
              className="w-full p-2 text-indigo-700 border-b-2 border-indigo-500 outline-none focus:bg-gray-300"
            >
              <option>Select</option>
              <option>fine motor skill</option>
              <option>cognitive development</option>
              <option>language and speech</option>
            </select>
          </div>

          <div className="mb-4">
            <label className="block mb-2 text-indigo-500" htmlFor="interests">
              What are your child's interests and preferences?
            </label>
            <select
              name="interests"
              value={formData.interests}
              onChange={handleChange}
              className="w-full p-2 text-indigo-700 border-b-2 border-indigo-500 outline-none focus:bg-gray-300"
            >
              <option>Select</option>
              <option>crawling</option>
              <option>love music</option>
            </select>
          </div>

          <div className="mb-4">
            <label className="block mb-2 text-indigo-500" htmlFor="areaSize">
              How much space do you have available for play and storage?
            </label>
            <select
              name="areaSize"
              value={formData.areaSize}
              onChange={handleChange}
              className="w-full p-2 text-indigo-700 border-b-2 border-indigo-500 outline-none focus:bg-gray-300"
            >
              <option>Select</option>
              <option>0-1 meter</option>
              <option>1-2 meter</option>
              <option>2-3 meter</option>
            </select>
          </div>

          <div className="mb-4">
            <label className="block mb-2 text-indigo-500" htmlFor="budget">
              What is your budget for the toy?
            </label>
            <select
              name="budget"
              value={formData.budget}
              onChange={handleChange}
              className="w-full p-2 text-indigo-700 border-b-2 border-indigo-500 outline-none focus:bg-gray-300"
            >
              <option>Select</option>
              <option>₹ 0 - 500</option>
              <option>₹ 500 - 1000</option>
              <option>₹ 1000 - 1500</option>
              <option>₹ 1500 - 2000</option>
              <option>₹ 2000 +</option>
            </select>
          </div>

          <div>
            <button
              type="submit"
              className="w-full bg-indigo-700 hover:bg-indigo-800 text-white font-bold py-2 px-4 rounded mt-4"
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ParentReview;
