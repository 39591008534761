import React from "react";
import Home from "../../pages/home/Home";
import ImageGallery from "../filter/ImageGallery";
import ProductList from "../product/ProductList";

const Landing = () =>{
    return(
       <>
        <Home/>
        <ImageGallery/>
        <ProductList/>
       </>
    )
}

export default Landing;