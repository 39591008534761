import React, { useEffect, useState } from 'react';
import api from '../../service/api';
import { Link, useNavigate } from 'react-router-dom';

const CartPage = () => {
  const [cart, setCart] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem('token');

    const fetchCart = async () => {
      try {
        const res = await api.get(`/cart/viewcart`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        setCart(res.data);
      } catch (error) {
        console.log(error);
      }
    };

    fetchCart();
  }, []);

  const total = cart.reduce((acc, item) => acc + item.product.price * item.quantity, 0).toFixed(2);

  const handleCheckout = () => {
    navigate('/checkout', { state: { cartItems: cart, totalAmount: total } });
  };

  return (
    <div className="p-4 max-w-xl mx-auto mt-16">
      <div className="bg-white shadow-lg rounded-lg overflow-hidden">
        <div className="flex items-center justify-between px-4 py-3 bg-gray-200">
          <h1 className="text-lg font-bold">Shopping Cart</h1>
          <span className="text-gray-600">({cart.length} items)</span>
        </div>
        <div className="p-4">
          {cart && cart.map(item => (
            <div key={item._id} className="flex items-center mb-4">
              <img
                className="h-16 w-16 object-contain rounded-lg mr-4"
                src={item.product.images[0]}
                alt={item.product.name}
              />
              <div className="flex-1">
                <h2 className="text-lg font-bold">{item.product.name}</h2>
                <span className="text-gray-600">&#8377;{item.product.price}</span>
              </div>
            </div>
          ))}
        </div>
        <div className="px-4 py-3 bg-gray-200">
          <div className="flex justify-between items-center">
            <span className="font-bold text-lg">Total:</span>
            <span className="font-bold text-lg">&#8377;{total}</span>
          </div>
          <button
            onClick={handleCheckout}
            className="block w-full mt-4  text-white font-bold py-2 px-4 rounded"
            style={{background : "#25bccc"}}
          >
            Checkout
          </button>
        </div>
      </div>
    </div>
  );
};

export default CartPage;
