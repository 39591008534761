import logo from './logo.svg';
import './App.css';
import { BrowserRouter } from 'react-router-dom';
import Routing from './navigation/Routing';
import Navbar from './layouts/NavBar';
import Footer from './layouts/Footer';
import ScrollToTop from './ScrollToTop';

function App() {
  return (
  <BrowserRouter>
  <Navbar/>
  <ScrollToTop/>
    <Routing/>
    <Footer/>
  </BrowserRouter>
  );
}

export default App;
