import Swal from 'sweetalert2';
import api from './../../service/api';

export const createOrder = (orderData, navigate) => async (dispatch) => {
  try {
    const token = localStorage.getItem('token');
    const response = await api.post('/checkout/create-order', orderData, {
      headers: { Authorization: `Bearer ${token}` },
    });
    dispatch({ type: 'CREATE_ORDER_SUCCESS', payload: response.data });

    Swal.fire({
      title: 'Order Confirmed!',
      text: 'Your order has been placed successfully.',
      icon: 'success',
      confirmButtonText: 'OK'
    });
    navigate('/')
    // navigate(`/confirmation/order/${response.data.checkoutId}`); // Navigate to the confirmation page with the checkoutId
  } catch (error) {
    dispatch({ type: 'CREATE_ORDER_FAIL', payload: error.message });
    Swal.fire({
      title: 'Order Failed!',
      text: 'There was an issue placing your order. Please try again.',
      icon: 'error',
      confirmButtonText: 'OK'
    });
  }
};
export const verifyPayment = (paymentData) => async (dispatch) => {
  try {
    const token = localStorage.getItem('token');
    const response = await api.post('/checkout/verify-payment', paymentData, {
      headers: { Authorization: `Bearer ${token}` },
    });
    dispatch({ type: 'VERIFY_PAYMENT_SUCCESS', payload: response.data });

    // Show SweetAlert2 success message for payment verification
    Swal.fire({
      title: 'Payment Verified!',
      text: 'Your payment has been successfully verified.',
      icon: 'success',
      confirmButtonText: 'OK'
    });
  } catch (error) {
    dispatch({ type: 'VERIFY_PAYMENT_FAIL', payload: error.message });

    // Show SweetAlert2 error message for payment verification failure
    Swal.fire({
      title: 'Payment Verification Failed!',
      text: 'We could not verify your payment. Please try again.',
      icon: 'error',
      confirmButtonText: 'OK'
    });
  }
};

export const fetchCheckout = (checkoutId) => async (dispatch) => {
  try {
    const token = localStorage.getItem('token');
    const response = await api.get(`/checkout/checkout/${checkoutId}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    dispatch({ type: 'FETCH_CHECKOUT_SUCCESS', payload: response.data.checkout });
  } catch (error) {
    dispatch({ type: 'FETCH_CHECKOUT_FAIL', payload: error.message });
    Swal.fire({
      title: 'Error!',
      text: 'Could not retrieve order details.',
      icon: 'error',
      confirmButtonText: 'OK'
    });
  }
};

