import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { createOrder, verifyPayment } from '../../redux/actions/checkoutActions';
import { useLocation, useNavigate } from 'react-router-dom';

const CheckoutForm = () => {
    const location = useLocation();
    const { cartItems, totalAmount } = location.state || { cartItems: [], totalAmount: 0 };
    const dispatch = useDispatch();
    const navigate = useNavigate(); // Add useNavigate hook here

    const [formData, setFormData] = useState({
        shipping: { name: '', email: '', phone: '', address: '', city: '', state: '', postalCode: '' },
        paymentMethod: 'Cash on Delivery',
    });

    const [couponCode, setCouponCode] = useState('');
    const [couponApplied, setCouponApplied] = useState(false);
    const [calculateAmmount, setCalculateAmmount] = useState(Number(totalAmount) + 199); // Initial totalAmount + shipping

    const shippingAmmount = 199; // Constant shipping amount

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, shipping: { ...formData.shipping, [name]: value } });
    };

    const applyCoupon = () => {
        if (couponCode === 'DISCOUNT10') {
            const discount = totalAmount * 0.1;
            const newTotal = (Number(totalAmount) + shippingAmmount) - discount;
            setCalculateAmmount(newTotal);
            setCouponApplied(true);
        } else {
            setCouponApplied(false);
            alert('Invalid Coupon Code');
        }
    };

    const handlePayment = async () => {
        try {
            const orderData = { ...formData, cartItems, totalAmount: calculateAmmount };
            const order = await dispatch(createOrder(orderData, navigate)); // Pass navigate here

            if (formData.paymentMethod === 'Pay Online - Razorpay') {
                const options = {
                    key: process.env.RAZORPAY_KEY_ID,
                    amount: calculateAmmount * 100,
                    currency: 'INR',
                    name: 'Pehell',
                    description: 'Thank you for your purchase',
                    order_id: order.razorpayOrderId,
                    handler: async (response) => {
                        const paymentData = {
                            razorpayOrderId: response.razorpay_order_id,
                            razorpayPaymentId: response.razorpay_payment_id,
                            razorpaySignature: response.razorpay_signature,
                            checkoutId: order._id,
                        };
                        await dispatch(verifyPayment(paymentData));
                    },
                    prefill: {
                        name: formData.shipping.name,
                        email: formData.shipping.email,
                        contact: formData.shipping.phone,
                    },
                    theme: {
                        color: '#3399cc',
                    },
                };
                const rzp = new window.Razorpay(options);
                rzp.open();
            }
        } catch (error) {
            console.error("Payment Error:", error);
        }
    };

    useEffect(() => {
        // Recalculate the amount when the coupon is applied or removed
        setCalculateAmmount(Number(totalAmount) + shippingAmmount);
    }, [totalAmount, shippingAmmount]);

    return (
        <div className="max-w-6xl mx-auto py-12 px-6">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-10">
            {/* Order Summary Section */}
            <div className="bg-white p-6 rounded-lg shadow-lg">
                <p className="text-2xl font-semibold mb-4">Order Summary</p>
                <p className="text-gray-600 mb-6">Review your items and select your shipping method.</p>
                {cartItems.map((item, index) => (
                    <div key={index} className="flex mb-4">
                        <img className="w-20 h-20 object-cover rounded-md mr-4" src={item.product.images[0]} alt={item.product.name} />
                        <div className="flex flex-col justify-between">
                            <span className="font-medium">{item.product.name}</span>
                            <span className="text-gray-500">Qty: {item.quantity}</span>
                            <p className="text-lg font-bold">&#8377; {item.product.price}</p>
                        </div>
                    </div>
                ))}
                <hr className="my-4" />
                <div className="flex justify-between mb-4">
                    <span className="font-semibold">Subtotal</span>
                    <span className="font-semibold">&#8377; {totalAmount}</span>
                </div>
                <div className="flex justify-between mb-4">
                    <span className="font-semibold">Shipping</span>
                    <span className="font-semibold">&#8377; {shippingAmmount}</span>
                </div>
                <div className="flex justify-between mb-6">
                    <span className="font-semibold text-xl">Total</span>
                    <span className="font-semibold text-xl">&#8377; {calculateAmmount}</span>
                </div>

                {/* Coupon Code Section */}
                <div className="flex justify-between items-center mb-6">
                    <div className="w-full flex items-center space-x-4">
                        <input
                            type="text"
                            placeholder="Enter Coupon Code"
                            className="w-full p-3 border rounded-lg border-gray-300 focus:ring-2 focus:ring-indigo-500"
                            value={couponCode}
                            onChange={(e) => setCouponCode(e.target.value)}
                        />
                        <button
                            className="px-6 py-3 text-white font-semibold rounded-lg hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                            style={{background : "#25bccc"}}
                            onClick={applyCoupon}
                        >
                            Apply 
                        </button>
                    </div>
                    
                  
                </div>

                  {/* Display coupon applied message */}
                  {couponApplied && (
                        <div className="mt-2 text-green-500">
                            <span>Coupon applied successfully!</span>
                        </div>
                    )}
            </div>

            {/* Shipping & Payment Form */}
            <div className="bg-white p-6 rounded-lg shadow-lg">
                <p className="text-2xl font-semibold mb-4">Shipping Details</p>
                <div className="space-y-4">
                    <input
                        type="text"
                        name="name"
                        placeholder="Full Name"
                        className="w-full p-3 border rounded-lg border-gray-300 focus:ring-2 focus:ring-indigo-500"
                        value={formData.shipping.name}
                        onChange={handleInputChange}
                        required
                    />
                    <input
                        type="email"
                        name="email"
                        placeholder="Email Address"
                        className="w-full p-3 border rounded-lg border-gray-300 focus:ring-2 focus:ring-indigo-500"
                        value={formData.shipping.email}
                        onChange={handleInputChange}
                        required
                    />
                    <input
                        type="text"
                        name="phone"
                        placeholder="Phone Number"
                        className="w-full p-3 border rounded-lg border-gray-300 focus:ring-2 focus:ring-indigo-500"
                        value={formData.shipping.phone}
                        onChange={handleInputChange}
                        required
                    />
                    <input
                        type="text"
                        name="address"
                        placeholder="Shipping Address"
                        className="w-full p-3 border rounded-lg border-gray-300 focus:ring-2 focus:ring-indigo-500"
                        value={formData.shipping.address}
                        onChange={handleInputChange}
                        required
                    />
                    <div className="grid grid-cols-2 gap-4">
                        <input
                            type="text"
                            name="city"
                            placeholder="City"
                            className="w-full p-3 border rounded-lg border-gray-300 focus:ring-2 focus:ring-indigo-500"
                            value={formData.shipping.city}
                            onChange={handleInputChange}
                            required
                        />
                        <input
                            type="text"
                            name="state"
                            placeholder="State"
                            className="w-full p-3 border rounded-lg border-gray-300 focus:ring-2 focus:ring-indigo-500"
                            value={formData.shipping.state}
                            onChange={handleInputChange}
                            required
                        />
                    </div>
                    <input
                        type="text"
                        name="postalCode"
                        placeholder="Postal Code"
                        className="w-full p-3 border rounded-lg border-gray-300 focus:ring-2 focus:ring-indigo-500"
                        value={formData.shipping.postalCode}
                        onChange={handleInputChange}
                        required
                    />

                    {/* Payment Method */}
                    <div className="mt-6">
                        <label className="block text-lg font-medium">Payment Method</label>
                        <div className="flex items-center space-x-6 mt-2">
                            <input
                                type="radio"
                                id="cod"
                                name="paymentMethod"
                                value="Cash on Delivery"
                                checked={formData.paymentMethod === 'Cash on Delivery'}
                                onChange={(e) => setFormData({ ...formData, paymentMethod: e.target.value })}
                            />
                            <label htmlFor="cod" className="text-gray-700">Cash on Delivery</label>

                            <input
                                type="radio"
                                id="razorpay"
                                name="paymentMethod"
                                value="Pay Online - Razorpay"
                                checked={formData.paymentMethod === 'Pay Online - Razorpay'}
                                onChange={(e) => setFormData({ ...formData, paymentMethod: e.target.value })}
                            />
                            <label htmlFor="razorpay" className="text-gray-700">Pay Online (Razorpay)</label>
                        </div>
                    </div>

                    {/* Proceed to Payment Button */}
                    <button
                        onClick={handlePayment}
                        className="w-full py-3 mt-6  text-white font-semibold rounded-lg hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                        style={{background : "#25bccc"}}
                    >
                        {formData.paymentMethod === 'Cash on Delivery' ? 'Place Order' : 'Proceed to Payment'}
                    </button>
                </div>
            </div>
        </div>
    </div>
    );
};

export default CheckoutForm;
