import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchProducts } from './../../redux/actions/productActions';
import { Link, useParams } from 'react-router-dom';
import { addToCart } from '../../redux/actions/cartActions';
import api from '../../service/api';

const ProductListByAge = () => {
    const {ageGroup} = useParams()
  const [quantity, setQuantity] = useState(1);
  const [isAdded, setIsAdded] = useState(null);  // Track if item is added to the cart
  const dispatch = useDispatch();

    const [products, setProducts] = useState('');

    useEffect(()=>{
        const fetchProduct = async()=>{
           try{
            const res = await api.get(`/products/filter/age-group?ageGroup=${ageGroup}`);
            console.log(res.data)
            setProducts(res.data)
           }catch(error){
            console.log(error)
           }

        };

        fetchProduct();
    })

  const handleAddToCart = (productId, quantity) => {
    dispatch(addToCart(productId, quantity));
    setIsAdded(productId);  // Set the product ID to indicate it's added
    setTimeout(() => setIsAdded(null), 2000);  // Reset after 2 seconds
  };

  return (
    <div className="text-center p-10">
      <h1 className="text-3xl">Our Products</h1>

      <section
        id="Projects"
        className="w-fit mx-auto grid grid-cols-1 lg:grid-cols-3 md:grid-cols-2 justify-items-center justify-center gap-y-20 gap-x-14 mt-10 mb-5"
      >
        {products && products.map((product, index) => (
          <div
            key={index}
            className="w-72 bg-white shadow-md rounded-xl duration-500 hover:scale-105 hover:shadow-xl"
          >
            <Link to={`/products/viewproduct/${product?._id}`}>
              <img
                src={product.images[0]} // Assumes 'images' is an array
                alt={product.name}
                className="h-80 w-72 object-cover rounded-t-xl"
              />
              <div className="px-4 py-3 w-72">
                <span className="text-gray-400 mr-3 uppercase text-xs">
                  {product.brand || 'Brand'}
                </span>
                <p className="text-lg font-bold text-black truncate block capitalize">
                  {product.name}
                </p>
                <div className="flex items-center">
                  <p className="text-lg font-semibold text-black cursor-auto my-3">
                    &#8377; {product.price}
                  </p>
                  {product.originalPrice && (
                    <del>
                      <p className="text-sm text-gray-600 cursor-auto ml-2">
                        &#8377; {product.originalPrice}
                      </p>
                    </del>
                  )}
                  <div className="ml-auto">
                    {/* Add to Cart Icon */}
                    <i
                      className={`bi text-2xl cursor-pointer transition-transform duration-300 ${
                        isAdded === product._id
                          ? 'bi-cart-check text-green-500 animate-pulse'
                          : 'bi-cart-plus'
                      }`}
                      onClick={() => handleAddToCart(product._id, quantity)}
                    ></i>
                  </div>
                </div>
              </div>
            </Link>
          </div>
        ))}
      </section>
    </div>
  );
};

export default ProductListByAge;
